body {
    margin: 0;
    font-family: Montserrat;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: silom;
    src: local("silom"), url(./fonts/Silom.ttf) format("truetype");
}

@font-face {
    font-family: Wire-One;
    src: local("WireOne-Regular"),
        url(./fonts/WireOne-Regular.ttf) format("truetype");
}

@font-face {
    font-family: Montserrat;
    src: local("Montserrat-Regular"),
        url(./fonts/Montserrat-Regular.ttf) format("truetype");
}

h1 {
    font-family: Wire-One;
    font-weight: bold;
    font-size: 4vw;
    color: #ffffff;
}

a {
    color: white;
    text-decoration: darkgray underline;
}
