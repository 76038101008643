#soundControler {
    position: fixed;
    bottom: 1vw;
    right: 1vw;
    height: 60px;
    width: 60px;
    cursor: pointer;
    z-index: 999999999;
}

#soundControler svg {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 800px) {
    #soundControler {
        height: 30px;
        width: 30px;
        top: 2vw;
        right: 3vw;
    }
}
